<template>
  <div>
    <form novalidate
          @submit.prevent="save">
      <md-card>
        <md-card-header>
          <div class="md-layout md-alignment-center-space-between">
            <h1 class="md-title md-layout-item md-size-40">Send Emails</h1>
            <div class="md-layout-item md-size-10">
              <md-button class="md-accent md-raised" type="submit">Save</md-button>
            </div>
          </div>
        </md-card-header>
        <md-card-content style="padding: 20px">
        <div class="md-layout-item md-size-100">
              <md-field>
                <label for="to">To</label>
                <md-input name="to" id="to" v-model="form.to"/>
                <div class="md-error" v-if="!$v.form.to.required">
                  {{ $t('missingValue') }}
                </div>
              </md-field>
            </div>
        </md-card-content>
        <md-card-content style="padding: 20px">
        <div class="md-layout-item md-size-100">
              <md-field>
                <label for="subject">Subject</label>
                <md-input name="subject" id="subject" v-model="form.subject"/>
              </md-field>
            </div>
        </md-card-content>
        <md-card-content style="padding: 20px">
          <div class="md-layout md-alignment-center-space-between">
            <div class="md-layout-item md-size-100">
                <textarea v-model="form.message" variant="outlined"  rows="10" cols="100" placeholder="Message"></textarea>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import formHelpersMixin from '@/mixins/formHelpersMixin';
import loaderMixin from '@/mixins/loaderMixin';
import { SEND_EMAIL } from '@/store/modules/user/userActions.type';

export default {
  name: 'Email',
  props: {
    id: [String, Number],
  },
  mixins: [
    validationMixin,
    formHelpersMixin,
    loaderMixin,
  ],
  data: () => ({
    form: {
      to: null,
      subject: null,
      message: null,
    },
  }),
  validations: {
    form: {
      to: {
        required,
      },
      subject: {
        required,
      },
      meesage: {
        required,
      },
    },
  },
  methods: {
    save() {
      const payload = {
        to: this.form.to,
        subject: this.form.subject,
        message: this.form.message,
      };
      if (!payload.to || !payload.subject || !payload.message) {
        this.$toasted.error('All fields are required', {
          type: 'error',
          duration: 5000,
          icon: 'alert',
        });
      } else {
        this.$store.dispatch(SEND_EMAIL, payload)
          .then((response) => {
            this.$log.info('SEND_EMAIL', response);
            this.$toasted.show(response.message, {
              type: 'success',
              duration: 5000,
              icon: 'check',
            });
            this.clearForm();
          })
          .catch((error) => {
            this.$log.error('SEND_EMAIL', error);
            this.$toasted.error(error.message, {
              type: 'error',
              duration: 5000,
              icon: 'alert',
            });
            if (error.status === 'validation_error' && error.errors) {
              this.errors = error.errors;
            }
          });
      }
    },
    clearForm() {
      this.form.to = null;
      this.form.subject = null;
      this.form.message = null;
    },
  },
};
</script>
