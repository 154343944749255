<template>
  <form novalidate @submit.prevent="save">
    <md-card>
      <md-card-content>
        <div class="md-layout md-alignment-center-space-between">
          <h1 class="md-title md-layout-item md-size-40">Editor Token</h1>

          <div class="md-layout-item md-size-10">
            <md-button class="md-accent md-raised" @click="save"
              >Save</md-button
            >
          </div>
        </div>

        <div class="md-layout md-alignment-center-space-between">
          <div class="md-layout-item md-size-66">
            <div class="md-layout-item md-small-size-30">
              <md-field class="disabled">
                <label for="id">ID</label>
                <md-input
                  name="id"
                  id="id"
                  class="disabled"
                  v-model="form.id"
                  disabled="disabled"
                  readonly
                />
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-30">
              <md-field class="disabled">
                <label for="name">Name</label>
                <md-input name="name"
                  id="name"
                  v-model="form.name"
                />
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-30">
              <md-field class="disabled">
                <label for="value">Token</label>
                <md-input
                  name="token"
                  id="token"
                  v-model="form.token"
                  required
                />
              </md-field>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {
  FETCH_TOKEN,
  UPDATE_TOKEN_A,
} from '@/store/modules/admin/single/editorToken/editorTokenActions.type';

export default {
  name: 'EditorToken',
  props: {
    id: String,
  },
  mixins: [validationMixin],
  data: () => ({
    form: {
      id: null,
      name: null,
      token: null,
    },
  }),
  validations: {
    form: {
      token: {
        required,
      },
    },
  },
  mounted() {
    this.initEditorTokenData(this.$route.params.id);
  },
  methods: {
    initEditorTokenData(id) {
      this.fetchEditorToken(id);
    },
    fetchEditorToken(id) {
      this.$store
        .dispatch(FETCH_TOKEN, id)
        .then((response) => {
          this.form.id = response.id;
          this.form.name = response.name;
          this.form.token = response.token;
        })
        .catch((error) => {
          this.$log.error('FETCH_TOKEN', error);
        });
    },
    canSubmitForm() {
      this.$v.$touch();
      this.errors = {};
      if (!this.$v.$invalid) {
        return true; // no validations error
      }
      return false;
    },
    save() {
      if (this.canSubmitForm()) {
        this.$store
          .dispatch(UPDATE_TOKEN_A, this.form)
          .then((response) => {
            this.$toasted.show(response.message, {
              type: 'success',
              duration: 5000,
              icon: 'check',
            });
            this.$log.info(UPDATE_TOKEN_A, response);
            this.$router.push({ name: 'adminEditorTokens' });
          })
          .catch((error) => {
            this.showErrorMsg(error);
            this.$log.error(UPDATE_TOKEN_A, error);
          });
      } else {
        this.showErrorMsg(this.$t('missingValue'));
      }
    },
    showErrorMsg(msg) {
      this.$toasted.show(msg, {
        type: 'error',
        position: 'top-right',
        duration: 5000,
        icon: 'alert',
      });
      if (msg.status === 'validation_error' && msg.errors) {
        this.errors = msg.errors;
      }
    },
  },
};
</script>
<style>
.disabled {
  color: #777777;
}

.md-field.md-theme-default.md-has-value .md-input {
  -webkit-text-fill-color: unset;
}
</style>
