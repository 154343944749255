<template>
  <md-card>
    <md-card-header>
      <div class="md-layout md-alignment-center-left listing-table-header">
        <div class="md-layout-item md-size-20 table-header-item">
          <h1 class="md-title">FavPages</h1>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-field>
            <label for="itemsPerPage">Display</label>
            <md-select :value="itemsPerPage" name="itemsPerPage" id="itemsPerPage" @md-selected="refreshList" md-dense>
              <md-option value="25">25</md-option>
              <md-option value="50">50</md-option>
              <md-option value="100">100</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-20 table-header-item">
          <md-field>
            <label for="sortBy">Sort by</label>
            <md-select :value="favPagesActiveSort.code" name="sortBy" id="sortBy" @md-selected="sortFn" md-dense>
              <md-option v-for="sortField in favPagesSortFields" :key="sortField.code" :value="sortField.code">
                {{ sortField.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-30 table-header-item">
          <md-field :md-clearable="true" @md-clear="clearsearch">
            <label for="search">Search...</label>
            <md-input name="search" id="search" :value="favPagesSearch" v-model="searchInput" @keyup="searchFn">
            </md-input>
          </md-field>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-button class="md-accent md-raised create-button" @click="search(searchInput)">
            Search
          </md-button>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-button class="md-accent md-raised create-button" :to="{ name: 'adminFavPageSingle', params: { id: null } }">
            Create
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <div class="md-layout">
        <div class="md-layout-item md-size-100 md-small-size-100 md-xsmall-size-100">
          <listing-page :items="favPagesItems" :singleRouteName="'adminFavPageSingle'" :columns="columns"
            @onRemove="onRemove">
          </listing-page>
        </div>
      </div>
    </md-card-content>

    <md-card-actions>
      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          <p>Showing {{ favPagesFrom }} to {{ favPagesTo }} of {{ favPagesTotal }} FavPages</p>
        </div>

        <div class="md-layout-item md-size-50">
          <rec-paginator :current-page="favPagesCurrentPage" :last-page="favPagesLastPage" :threshold="2"
            :has-prev-next="true" :has-first-last="true" @onFirstPage="onFirstPage" @onPreviousPage="onPreviousPage"
            @onNextPage="onNextPage" @onLastPage="onLastPage" @onChangePage="onChangePage">
          </rec-paginator>
        </div>
      </div>
    </md-card-actions>
  </md-card>
</template>

<script>
import ListingPage from '@/components/page-templates/ListingPage.vue';
import RecPaginator from '@/components/pagination/RecPaginator.vue';

import {
  FETCH_FAV_PAGES,
  UPDATE_ACTIVE_SORT_FIELD_FAV_PAGES,
  UPDATE_SEARCH_FAV_PAGES,
  CLEAR_SEARCH_FAV_PAGES,
  ADMIN_DELETE_FAV_PAGE,
} from '@/store/modules/admin/resources/favPages/favPagesActions.type';

export default {
  name: 'FavPages',
  components: {
    ListingPage,
    RecPaginator,
  },
  data: () => ({
    columns: [
      {
        name: 'country',
        label: 'Country',
      },
      {
        name: 'title',
        label: 'Title',
      },
      {
        name: 'type',
        label: 'Type',
      },
      {
        name: 'disabled',
        label: 'Disabled',
      },
      {
        name: 'lines_count',
        label: '#Lines',
      },
    ],
    searchInput: null,
  }),
  computed: {
    favPagesItems() {
      return this.$store.getters.favPagesItems;
    },
    itemsPerPage() {
      return this.$store.getters.favPagesPerPage;
    },
    favPagesCurrentPage() {
      return this.$store.getters.favPagesCurrentPage;
    },
    favPagesLastPage() {
      return this.$store.getters.favPagesLastPage;
    },
    favPagesFrom() {
      return this.$store.getters.favPagesFrom;
    },
    favPagesTo() {
      return this.$store.getters.favPagesTo;
    },
    favPagesTotal() {
      return this.$store.getters.favPagesTotal;
    },
    favPagesSortFields() {
      return this.$store.getters.favPagesSortFields;
    },
    favPagesActiveSort() {
      return this.$store.getters.favPagesActiveSort;
    },
    favPagesSearch() {
      return this.$store.getters.favPagesSearch;
    },
  },
  mounted() {
    this.initFavPages();
  },
  methods: {
    initFavPages() {
      const params = {
        page: this.favPagesCurrentPage,
        perPage: this.itemsPerPage,
      };
      this.fetchFavPages(params);
    },
    fetchFavPages(params) {
      this.$store.dispatch(FETCH_FAV_PAGES, params).then((response) => {
        this.$log.info('FETCH_FAV_PAGES', response);
      }).catch((error) => {
        this.$log.error('FETCH_FAV_PAGES', error);
      });
    },
    onFirstPage() {
      const params = {
        page: 1,
        perPage: this.itemsPerPage,
      };
      this.fetchFavPages(params);
    },
    onPreviousPage() {
      const params = {
        page: this.favPagesCurrentPage - 1,
        perPage: this.itemsPerPage,
      };
      this.fetchFavPages(params);
    },
    onChangePage(data) {
      const params = {
        page: data,
        perPage: this.itemsPerPage,
      };
      this.fetchFavPages(params);
    },
    onNextPage() {
      const params = {
        page: this.favPagesCurrentPage + 1,
        perPage: this.itemsPerPage,
      };
      this.fetchFavPages(params);
    },
    onLastPage() {
      const params = {
        page: this.favPagesLastPage,
        perPage: this.itemsPerPage,
      };
      this.fetchFavPages(params);
    },
    refreshList(data) {
      const params = {
        page: 1,
        perPage: data,
      };
      this.fetchFavPages(params);
    },
    clearsearch() {
      this.$store.dispatch(CLEAR_SEARCH_FAV_PAGES).then(() => {
        const params = {
          page: 1,
          perPage: this.itemsPerPage,
        };
        this.fetchFavPages(params);
      }).catch((error) => {
        this.$log.error('CLEAR_SEARCH_FAV_PAGES', error);
      });
    },
    searchFn(data) {
      if (data.key === 'Enter') {
        const searchValue = data.target.value;
        this.search(searchValue);
      }
    },
    search(searchValue) {
      if (!searchValue || searchValue === '') {
        // clear search
        this.$store.dispatch(CLEAR_SEARCH_FAV_PAGES).then(() => {
          const params = {
            page: 1,
            perPage: this.itemsPerPage,
          };
          this.fetchFavPages(params);
        }).catch((error) => {
          this.$log.error('CLEAR_SEARCH_FAV_PAGES', error);
        });
      } else if (searchValue !== '') {
        this.$store.dispatch(UPDATE_SEARCH_FAV_PAGES, searchValue).then(() => {
          const params = {
            page: 1,
            perPage: this.itemsPerPage,
          };
          this.fetchFavPages(params);
        }).catch((error) => {
          this.$log.error('UPDATE_SEARCH_FAV_PAGES', error);
        });
      }
    },
    sortFn(data) {
      this.$store.dispatch(UPDATE_ACTIVE_SORT_FIELD_FAV_PAGES, data).then(() => {
        const params = {
          page: 1,
          perPage: this.itemsPerPage,
        };
        this.fetchFavPages(params);
      }).catch((error) => {
        this.$log.error('UPDATE_ACTIVE_SORT_FIELD_FAV_PAGES', error);
      });
    },
    create() {
      this.$toasted.info('TO IMPLEMENT', {
        type: 'info',
        position: 'top-right',
        duration: 5000,
        icon: 'check',
      });
    },
    onRemove(item) {
      this.$store.dispatch(ADMIN_DELETE_FAV_PAGE, item.id)
        .then((response) => {
          this.initFavPages();
          this.$log.info('ADMIN_DELETE_FAV_PAGE', response);
          this.$toasted.show(response.message, {
            type: 'success',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error('ADMIN_DELETE_FAV_PAGE', error);
          this.$toasted.error(error.message, {
            type: 'error',
            duration: 5000,
            icon: 'alert',
          });
        });
    },
  },
};
</script>

<style lang="scss">
.listing-table-header {
  .table-header-item {
    padding: 0.5rem;

    .md-field {
      margin: 0;
    }

    .create-button {
      float: right;
    }
  }
}

.md-card-actions,
.md-card-content,
.md-card-header {
  padding: 1rem;
}
</style>
