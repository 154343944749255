<template>
  <form novalidate @submit.prevent="save">
    <md-card>
      <md-card-content>
        <div class="md-layout md-alignment-center-space-between">
          <h1 class="md-title md-layout-item md-size-40">Setting</h1>

          <div class="md-layout-item md-size-10">
            <md-button class="md-accent md-raised" @click="save"
              >Save</md-button
            >
          </div>
        </div>

        <div class="md-layout md-alignment-center-space-between">
          <div class="md-layout-item md-size-66">
            <div class="md-layout-item md-small-size-30">
              <md-field class="disabled">
                <label for="id">ID</label>
                <md-input
                  name="id"
                  id="id"
                  class="disabled"
                  v-model="form.id"
                  disabled="disabled"
                  readonly
                />
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-30">
              <md-field class="disabled">
                <label for="key">Key</label>
                <md-input name="key"
                  id="key"
                  class="disabled"
                  v-model="form.key"
                  disabled="disabled"
                  readonly
                />
                <div class="md-error disabled" disabled="disabled" readonly v-if="!$v.form.key.required">
                  {{ $t("missingValue") }}
                </div>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-30">
              <md-field class="disabled">
                <label for="value">Value</label>
                <md-input
                  name="value"
                  id="value"
                  v-model="form.value"
                  required
                />
              </md-field>
            </div>
          </div>
        </div>

        <div class="md-layout-item md-small-size-30">
              <md-field class="disabled">
                <label for="description">Description</label>
                <md-textarea
                  name="description"
                  id="description"
                  v-model="form.description"
                  class="disabled"
                  disabled="disabled"
                  readonly
                />
              </md-field>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {
  FETCH_SETTING,
  UPDATE_SETTING_A,
} from '@/store/modules/admin/single/setting/settingActions.type';

export default {
  name: 'Setting',
  props: {
    id: String,
  },
  mixins: [validationMixin],
  data: () => ({
    form: {
      id: null,
      key: null,
      value: null,
      description: null,
    },
  }),
  validations: {
    form: {
      key: {
        required,
      },
      value: {
        required,
      },
    },
  },
  mounted() {
    this.initSettingData(this.$route.params.id);
  },
  methods: {
    initSettingData(id) {
      this.fetchSetting(id);
    },
    fetchSetting(id) {
      this.$store
        .dispatch(FETCH_SETTING, id)
        .then((response) => {
          this.form.id = response.id;
          this.form.key = response.key;
          this.form.value = response.value;
          this.form.description = response.description;
        })
        .catch((error) => {
          this.$log.error('FETCH_SETTING', error);
        });
    },
    canSubmitForm() {
      this.$v.$touch();
      this.errors = {};
      if (!this.$v.$invalid) {
        return true; // no validations error
      }
      return false;
    },
    save() {
      if (this.canSubmitForm()) {
        this.$store
          .dispatch(UPDATE_SETTING_A, this.form)
          .then((response) => {
            this.$toasted.show(response.message, {
              type: 'success',
              duration: 5000,
              icon: 'check',
            });
            this.$log.info(UPDATE_SETTING_A, response);
          })
          .catch((error) => {
            this.showErrorMsg(error);
            this.$log.error(UPDATE_SETTING_A, error);
          });
      } else {
        this.showErrorMsg(this.$t('missingValue'));
      }
    },
    showErrorMsg(msg) {
      this.$toasted.show(msg, {
        type: 'error',
        position: 'top-right',
        duration: 5000,
        icon: 'alert',
      });
      if (msg.status === 'validation_error' && msg.errors) {
        this.errors = msg.errors;
      }
    },
  },
};
</script>
<style>
.disabled {
  color: #777777;
}

.md-field.md-theme-default.md-has-value .md-input {
  -webkit-text-fill-color: unset;
}
</style>
