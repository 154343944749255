<template>
  <md-card>
    <md-card-header>
      <div class="md-layout md-alignment-center-left listing-table-header">
        <div class="md-layout-item md-size-20 table-header-item">
          <h1 class="md-title">Settings</h1>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-field>
            <label for="itemsPerPage">Display</label>
            <md-select :value="itemsPerPage" name="itemsPerPage" id="itemsPerPage" @md-selected="refreshList" md-dense>
              <md-option value="25">25</md-option>
              <md-option value="50">50</md-option>
              <md-option value="100">100</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-20 table-header-item">
          <md-field>
            <label for="sortBy">Sort by</label>
            <md-select :value="settingsActiveSort.code" name="sortBy" id="sortBy" @md-selected="sortFn" md-dense>
              <md-option v-for="sortField in settingsSortFields" :key="sortField.code" :value="sortField.code">
                {{ sortField.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-30 table-header-item">
          <md-field :md-clearable="true" @md-clear="clearsearch">
            <label for="search">Search...</label>
            <md-input name="search" id="search" v-model="searchInput" :value="settingsSearch" @keyup="searchFn">
            </md-input>
          </md-field>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-button class="md-accent md-raised create-button" @click="search(searchInput)">
            Search
          </md-button>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-button class="md-accent md-raised create-button" @click="create">
            Create
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <listing-page :items="settingsItems" :singleRouteName="'adminSettingPageSingle'" :columns="columns"
            @onRemove="onRemove" :active="active">
          </listing-page>
        </div>
      </div>
    </md-card-content>

    <md-card-actions>
      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          Showing {{ settingsFrom }} to {{ settingsTo }} of {{ settingsTotal }}
        </div>
        <div class="md-layout-item md-size-50">
          <rec-paginator :current-page="settingsCurrentPage" :last-page="settingsLastPage" :threshold="2"
            :has-prev-next="true" :has-first-last="true" @onFirstPage="onFirstPage" @onPreviousPage="onPreviousPage"
            @onNextPage="onNextPage" @onLastPage="onLastPage" @onChangePage="onChangePage">

          </rec-paginator>
        </div>
      </div>
      <SettingModal @onClose="onClose" :keys="allKeys" :active="active"></SettingModal>
    </md-card-actions>
  </md-card>
</template>

<script>
import ListingPage from '@/components/page-templates/ListingPage.vue';
import RecPaginator from '@/components/pagination/RecPaginator.vue';
import SettingModal from '@/components/modals/SettingModal.vue';

import {
  FETCH_SETTINGS,
  CLEAR_SEARCH_SETTINGS,
  UPDATE_SEARCH_SETTINGS,
  UPDATE_ACTIVE_SORT_FIELD_SETTINGS,
  DELETE_SETTING,
} from '@/store/modules/admin/resources/settings/settingsActions.type';


export default {
  name: 'Settings',
  components: {
    ListingPage,
    RecPaginator,
    SettingModal,
  },
  data: () => ({
    columns: [
      {
        name: 'key',
        label: 'Key',
      },
      {
        name: 'value',
        label: 'Value',
      },
      {
        name: 'description',
        label: 'Description',
      },
    ],
    active: false,
    keys: null,
    searchInput: null,
  }),
  computed: {
    allKeys() {
      return this.keys;
    },
    settingsItems() {
      return this.$store.getters.settingsItems;
    },
    itemsPerPage() {
      return this.$store.getters.itemsPerPage;
    },
    settingsCurrentPage() {
      return this.$store.getters.settingsCurrentPage;
    },
    settingsLastPage() {
      return this.$store.getters.settingsLastPage;
    },
    settingsFrom() {
      return this.$store.getters.settingsFrom;
    },
    settingsTo() {
      return this.$store.getters.settingsTo;
    },
    settingsTotal() {
      return this.$store.getters.settingsTotal;
    },
    settingsSortFields() {
      return this.$store.getters.settingsSortFields;
    },
    settingsActiveSort() {
      return this.$store.getters.settingsActiveSort;
    },
    settingsSearch() {
      return this.$store.getters.settingsSearch;
    },
  },
  mounted() {
    this.initSettings();
  },
  methods: {
    onClose() {
      this.active = false;
    },
    initSettings() {
      const params = {
        page: this.settingsCurrentPage,
        perPage: this.itemsPerPage,
      };
      this.fetchSettings(params);
    },
    fetchSettings(params) {
      this.$store.dispatch(FETCH_SETTINGS, params)
        .then((response) => {
          this.keys = response.data.keys;
          this.$log.info('FETCH_SETTINGS', response);
        })
        .catch((error) => {
          this.$log.error('FETCH_SETTINGS', error);
        });
    },
    onFirstPage() {
      const params = {
        page: 1,
        perPage: this.itemsPerPage,
      };
      this.fetchSettings(params);
    },
    onPreviousPage() {
      const params = {
        page: this.settingsCurrentPage - 1,
        perPage: this.itemsPerPage,
      };
      this.fetchSettings(params);
    },
    onChangePage(data) {
      const params = {
        page: data,
        perPage: this.itemsPerPage,
      };
      this.fetchSettings(params);
    },
    onNextPage() {
      const params = {
        page: this.settingsCurrentPage + 1,
        perPage: this.itemsPerPage,
      };
      this.fetchSettings(params);
    },
    onLastPage() {
      const params = {
        page: this.settingsLastPage,
        perPage: this.itemsPerPage,
      };
      this.fetchSettings(params);
    },
    refreshList(data) {
      const params = {
        page: 1,
        perPage: data,
      };
      this.fetchSettings(params);
    },
    clearsearch() {
      this.$store.dispatch(CLEAR_SEARCH_SETTINGS)
        .then(() => {
          const params = {
            page: 1,
            perPage: this.itemsPerPage,
          };
          this.fetchSettings(params);
        })
        .catch((error) => {
          this.$log.error('CLEAR_SEARCH_SETTINGS', error);
        });
    },
    searchFn(data) {
      if (data.key === 'Enter') {
        const searchValue = data.target.value;
        this.search(searchValue);
      }
    },
    search(searchValue) {
      if (!searchValue || searchValue === '') {
        this.$store.dispatch(CLEAR_SEARCH_SETTINGS)
          .then(() => {
            const params = {
              page: 1,
              perPage: this.itemsPerPage,
            };
            this.fetchSettings(params);
          })
          .catch((error) => {
            this.$log.error('CLEAR_SEARCH_SETTINGS', error);
          });
      } else if (searchValue !== '') {
        this.$store.dispatch(UPDATE_SEARCH_SETTINGS, searchValue)
          .then(() => {
            const params = {
              page: 1,
              perPage: this.itemsPerPage,
            };
            this.fetchSettings(params);
          })
          .catch((error) => {
            this.$log.error('UPDATE_SEARCH_SETTINGS', error);
          });
      }
    },
    sortFn(data) {
      this.$store.dispatch(UPDATE_ACTIVE_SORT_FIELD_SETTINGS, data)
        .then(() => {
          const params = {
            page: 1,
            perPage: this.itemsPerPage,
          };
          this.fetchSettings(params);
        })
        .catch((error) => {
          this.$log.error('UPDATE_ACTIVE_SORT_FIELD_SETTINGS', error);
        });
    },
    onRemove(item) {
      this.$store.dispatch(DELETE_SETTING, item.id)
        .then((response) => {
          this.$log.info('DELETE_SETTING', response);
          const params = {
            page: this.settingsCurrentPage,
            perPage: this.itemsPerPage,
          };
          this.fetchSettings(params);
          this.$toasted.show(response.message, {
            type: 'success',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error('DELETE_SETTING', error);
        });
    },
    create() {
      this.active = false; // to open the modal even i closed it before by clicking outside
      this.active = true;
    },
  },
};
</script>

<style lang="scss">
.listing-table-header {
  .table-header-item {
    padding: 0.5rem;

    .md-field {
      margin: 0;
    }

    .create-button {
      float: right;
    }
  }
}

.md-card-actions,
.md-card-content,
.md-card-header {
  padding: 1rem;
}
</style>
