<template>
  <div>
    <md-dialog :md-active.sync="active">
      <md-dialog-title>Add New Token</md-dialog-title>
      <md-dialog-content>
        <md-field>
          <label>Name</label>
          <md-input v-model="name" type="text"></md-input>
        </md-field>
        <md-field>
          <label>Token</label>
          <md-input v-model="token" type="text" required></md-input>
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="onClose()">Close</md-button>
        <md-button class="md-primary" @click="addNewToken()">Save</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import {
  CREATE_TOKEN,
} from '@/store/modules/admin/single/editorToken/editorTokenActions.type';

import {
  FETCH_EDITOR_TOKENS,
} from '@/store/modules/admin/resources/editorTokens/editorTokensActions.type';

export default {
  name: 'EditorTokenModal',
  props: {
    active: Boolean,
  },
  data: () => ({
    name: null,
    token: null,
  }),
  computed: {
    editorTokensItems() {
      return this.$store.getters.editorTokensItems;
    },
    editorTokensItemsPerPage() {
      return this.$store.getters.editorTokensItemsPerPage;
    },
  },
  methods: {
    onClose() {
      this.$emit('onClose', false);
    },
    fetchEditorTokens() {
      const params = {
        page: 1,
        perPage: this.editorTokensItemsPerPage,
      };
      this.$store.dispatch(FETCH_EDITOR_TOKENS, params)
        .then((response) => {
          this.$log.info('FETCH_EDITOR_TOKENS', response);
        })
        .catch((error) => {
          this.$log.error('FETCH_EDITOR_TOKENS', error);
        });
    },
    addNewToken() {
      if (!this.validatModalData()) {
        this.showErrorMsg(this.$t('missingValue'));
        return;
      }
      const payload = {
        name: this.name,
        token: this.token,
      };
      this.$store.dispatch(CREATE_TOKEN, payload)
        .then((response) => {
          this.onClose();
          this.fetchEditorTokens();
          this.clearModalData();
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error(CREATE_TOKEN, error);
          this.showErrorMsg(error);
        });
    },
    validatModalData() {
      if (this.token == null) {
        return false;
      }
      return true;
    },
    clearModalData() {
      this.name = null;
      this.token = null;
    },
    showErrorMsg(msg) {
      this.$toasted.show(msg, {
        type: 'error',
        position: 'top-right',
        duration: 5000,
        icon: 'alert',
      });
    },
  },
  watch: {
    active: {
      handler(val) {
        this.active = val;
      },
    },
  },
};
</script>
<style>
.md-dialog-container {
  width: 50%;
}
.md-menu-content-bottom-start {
  z-index: 9999;
}
</style>
