<template>
  <div>
    <md-dialog :md-active.sync="active">
      <md-dialog-title>{{ $t('userTopTabs.settingModalTitle') }}</md-dialog-title>
      <md-dialog-content>
        <md-field>
          <label v-show="!key">{{ $t('userTopTabs.key') }}</label>
          <select name="key" v-model="key" style=" outline: none; border: none; width:150%;">
              <option :key ="keyItem" v-for="keyItem in keys" :value="keyItem">{{keyItem}}</option>
          </select>
        </md-field>
        <md-field>
          <label>{{ $t('userTopTabs.value') }}</label>
          <md-input v-model="value" type="text" required></md-input>
        </md-field>
        <md-field>
          <label>{{ $t('userTopTabs.description') }}</label>
          <md-input v-model="description" type="text"></md-input>
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="onClose()">{{ $t('userTopTabs.close') }}</md-button>
        <md-button class="md-primary" @click="addNewSetting()">{{ $t('userTopTabs.save') }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import {
  CREATE_SETTING,
} from '@/store/modules/admin/single/setting/settingActions.type';

import {
  FETCH_SETTINGS,
} from '@/store/modules/admin/resources/settings/settingsActions.type';

export default {
  name: 'SettingModal',
  props: {
    active: Boolean,
    keys: Array,
  },
  data: () => ({
    key: null,
    value: null,
    description: null,
  }),
  computed: {
    settingsItems() {
      return this.$store.getters.settingsItems;
    },
    itemsPerPage() {
      return this.$store.getters.itemsPerPage;
    },
  },
  methods: {
    onClose() {
      this.$emit('onClose', false);
    },
    selectSite(item) {
      this.site_id = item.id;
    },
    fetchSettings() {
      const params = {
        page: 1,
        perPage: this.itemsPerPage,
      };
      this.$store.dispatch(FETCH_SETTINGS, params)
        .then((response1) => {
          this.$log.info('FETCH_SETTINGS', response1);
        })
        .catch((error) => {
          this.$log.error('FETCH_SETTINGS', error);
        });
    },
    addNewSetting() {
      if (!this.validatModalData()) {
        this.showErrorMsg(this.$t('missingValue'));
        return;
      }
      const payload = {
        key: this.key,
        value: this.value,
        description: this.description,
      };
      this.$store.dispatch(CREATE_SETTING, payload)
        .then((response) => {
          this.onClose();
          this.fetchSettings();
          this.clearModalData();
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error(CREATE_SETTING, error);
          this.showErrorMsg(error);
        });
    },
    validatModalData() {
      if (this.key == null) {
        return false;
      }
      return true;
    },
    clearModalData() {
      this.key = null;
      this.value = null;
      this.description = null;
    },
    showErrorMsg(msg) {
      this.$toasted.show(msg, {
        type: 'error',
        position: 'top-right',
        duration: 5000,
        icon: 'alert',
      });
    },
  },
  watch: {
    active: {
      handler(val) {
        this.active = val;
      },
    },
  },
};
</script>
<style>
.md-dialog-container {
  width: 50%;
}
.md-menu-content-bottom-start {
  z-index: 9999;
}
</style>
