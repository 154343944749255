<template>
  <md-card>
    <md-card-header>
      <div class="md-layout md-alignment-center-left">
        <div class="md-layout-item md-size-20 table-header-item">
          <h1 class="md-title">Banner Visibility</h1>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <div class="md-layout">
        <md-table v-model="items">
          <md-table-row slot="md-table-row"
                      slot-scope="{ item }"
                      md-selectable="single">

            <md-table-cell v-for="column in columns"
                           :key="column.name"
                           :md-label="column.label">
                <span v-html="item[column.name]"></span>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </md-card-content>

  </md-card>
</template>

<script>
export default {
  name: 'BannerSettings',
  data: () => ({
    items: [
      {
        value: 'all', free: 'Visible', premium: 'Visible', notlogged: 'Visible',
      },
      {
        value: 'premium', free: 'Not Visible', premium: 'Visible', notlogged: 'Not Visible',
      },
      {
        value: 'free', free: 'Visible', premium: 'Not Visible', notlogged: 'Not Visible',
      },
      {
        value: 'not_logged', free: 'Not Visible', premium: 'Not Visible', notlogged: 'Visible',
      },
      {
        value: 'no record', free: 'Not Visible', premium: 'Not Visible', notlogged: 'Not Visible',
      },
      {
        value: 'wrong value (ex: roy)', free: 'Not Visible', premium: 'Not Visible', notlogged: 'Not Visible',
      },
    ],
    columns: [
      {
        name: 'value',
        label: 'Value/User Type',
      },
      {
        name: 'free',
        label: 'Free User',
      },
      {
        name: 'premium',
        label: 'Premium User',
      },
      {
        name: 'notlogged',
        label: 'User is not logged in',
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.md-table .md-table-row .md-table-cell:first-child span{
  font-weight: 800;
  font-size: 16px;
  color: #f93ede;
}

.md-table .md-table-row .md-table-cell:not(:first-child) span{
  font-weight: 500;
  font-size: 14px;
  color: #1020a9;
}
</style>
